$g: #01473c;

@mixin content {
    display: block;

    content: '';
}

@mixin mq-pc {
    @media screen and (min-width: 768px) {
        @content;
    }
}

@mixin mq-sp {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin mq-pad {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mq-pc-and-pad {
    @media screen and (min-width: 768px) {
        @content;
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    word-wrap: break-word;

    outline: none !important;

    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-feature-settings: 'palt';
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    font-size: 100%;

    margin: 0;
    padding: 0;

    vertical-align: baseline;

    border: 0;
    outline: 0;
    background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
em,
strong {
    font-style: normal;

    display: block;
}

nav ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

span {
    display: block;
}

q:before,
q:after {
    content: '';
    content: none;
}

img {
    width: 100%;

    vertical-align: bottom;
}

a {
    font-size: 100%;

    display: block;

    margin: 0;
    padding: 0;

    cursor: pointer;
    transition: all .6s;
    vertical-align: baseline;
    text-decoration: none;

    color: currentColor;
    background: transparent;

    &:hover {
        @include mq-pc {
            opacity: .6;
        }
    }
}

ins {
    text-decoration: none;

    color: currentColor;
    background-color: #ff9;
}

mark {
    font-weight: bold;
    font-style: italic;

    color: currentColor;
    background-color: #ff9;
}

del {
    text-decoration: line-through;
}

svg {
    width: 100%;
    height: 100%;
}

i {
    display: block;
}

abbr[title],
dfn[title] {
    cursor: help;

    border-bottom: 1px dotted;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

hr {
    display: block;

    height: 1px;
    margin: 1em 0;
    padding: 0;

    border: 0;
    border-top: 1px solid #ccc;
}

input,
select {
    vertical-align: middle;

    -moz-appearance: none;
    -webkit-appearance: none;
}

textarea {
    font-size: 1.4rem;

    width: 100%;
    min-height: 300px;
    padding: 10px;

    resize: none;

    border: 1px solid #ccc;
    outline: none;
}

input {
    font-size: 1.4rem;

    position: absolute;

    width: 100%;
    height: 100%;
    padding: 10px;

    border: 1px solid #ccc;
    outline: none;
    background-color: transparent;
}

::-moz-selection {
    color: white;
    background: $g;
}

::selection {
    color: white;
    background: $g;
}

.comp {
    position: absolute;
    z-index: 1000;
    top: 104px;
    left: 0;

    pointer-events: none;

    opacity: .4;
}

@include mq-pc {
    .pc-none {
        display: none !important;
    }
}

@include mq-sp {
    .sp-none {
        display: none !important;
    }
}

.pe_none {
    pointer-events: none;
}

html,
body {
    font-family: 'Lato', 'Avenir', 'Yu Gothic', YuGothic, sans-serif;
    font-size: 62.5%;
    font-weight: 400;
    line-height: 1.5;

    width: 100%;
    height: 100%;

    color: black;
    background: #fff;

    @include mq-sp() {
        overflow: hidden;
    }
}

.lazy {
    &.is-in {
        opacity: 1;
    }

    transform: translateX(0) translateY(0) translateZ(0);
    transition: opacity 0.2s;
    opacity: 0;

}

.scroll-in {
    &.is-in {
        .fade {
            animation-name: fade;
            animation-duration: 1.4s;
            animation-timing-function: ease;
            animation-delay: 0s;
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-fill-mode: forwards;

        }
    }

    .fade {
        transform: translateX(0) translateZ(0);
        -webkit-mask-size: 100% 200%;
        -webkit-mask-position: 0% 200%;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgb(0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
    }
}

@keyframes fade {
    0% {
        -webkit-mask-position: 0% 200%;
    }

    100% {
        -webkit-mask-position: 0% 50%;
    }
}

#panel-wrap {
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: background .6s;

    &.is-w {
        background: rgba(255, 255, 255, 0.5);
    }

    #panel {
        position: fixed;
        z-index: 50;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        transform: translate3d(-100%, -100%, 0);

        background: $g;
    }

    .w-r {
        position: absolute;
        top: 0;
        left: calc(100vw - 1px);
        width: 10.5vh;
        height: 100vh;
    }
}

.hidden {
    overflow: hidden;
}

.scroll-cont {
    position: fixed;
    top: 0;
    left: 0;

    overflow-x: hidden;
    overflow-y: scroll;

    width: 100%;

    @include mq-pc() {
        overflow: hidden;
    }

    @include mq-sp() {
        height: 100%;

        transform: translate3d(0, 0, 0) !important;

        will-change: scroll-position;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
    }
}

.ig {
    position: relative;

    width: 20px;
    height: 20px;

    .cursor-pos {
        top: -9px;
        left: -8px;

        width: 36px;
        height: 36px;
    }
}

.fb {
    position: relative;

    width: 20px;
    height: 20px;

    .cursor-pos {
        top: -9px;
        left: -8px;

        width: 36px;
        height: 36px;
    }
}

.none {
    display: none !important;
}

strong {
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 1.3;

    letter-spacing: 3px;

    @include mq-pc {
        font-size: 3rem;
    }
}

#btn-m {
    position: fixed;
    z-index: 70;
    top: 5px;
    left: 5px;

    width: 60px;
    height: 50px;
    transition: opacity .6s;
    transition-delay: 0.8s;
    mix-blend-mode: difference;
    cursor: pointer;

    @include mq-pc {
        top: 40px;
        left: 40px;
        height: 60px;
    }

    &.is-hide {
        transition-delay: 0s;
        opacity: 0;
        pointer-events: none;
    }

    &:hover {
        @include mq-pc {
            .default {
                &:before {
                    transform: translateX(100%) translateY(0) translateZ(0);
                }

                &:after {
                    transform: translateX(100%) translateY(0) translateZ(0);
                }
            }

            .on {

                &:before {
                    transform: translateX(0%) translateY(0) translateZ(0);
                }

                &:after {
                    transform: translateX(0%) translateY(0) translateZ(0);
                }
            }
        }
    }

    span {
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        margin: auto;

        pointer-events: none;
        overflow: hidden;

        &:before {
            position: absolute;
            top: 0;
            left: 0;

            width: 30px;
            height: 1px;

            background: #fff;
            transition: transform .6s;
            @include content;
        }

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 30px;
            height: 1px;
            transition: transform .6s;
            background: #fff;
            transition-delay: .1s;
            @include content;
        }
    }

    .default {
        width: 30px;
        height: 10px;
    }

    .on {
        width: 30px;
        height: 10px;

        &:before {
            // background: $g;
            transform: translateX(-100%) translateY(0) translateZ(0);
            transition-delay: 0.2s;
        }

        &:after {
            // background: $g;
            transform: translateX(-100%) translateY(0) translateZ(0);
            transition-delay: 0.2s;
        }
    }

    .cursor-pos {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;
        margin: auto;
    }
}

#btn-cart {
    position: fixed;
    z-index: 50;
    top: 55px;
    left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    mix-blend-mode: difference;

    width: 60px;
    height: 50px;
    padding-right: 3px;
    transition: opacity .6s;
    transition-delay: 0.8s;

    @include mq-pc {
        top: 100px;
        left: 40px;
        height: 60px;
    }

    .icon {
        width: 35px;
        height: 35px;
    }

    .cursor-pos {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;
        margin: auto;
    }
}

.sub {
    display: flex;

    width: 100vw;
    margin-right: auto;
    margin-left: auto;

    justify-content: flex-end;

    @include mq-pc {
        width: 92vw;
    }

    i {
        width: 20px;
        height: 1px;
        margin-top: 9px;
        margin-right: 10px;

        background-color: currentColor;

        @include mq-pc {
            margin-top: 11px;
            margin-right: 16px;
            width: 30px;
            height: 1px;
        }
    }

    em {
        font-size: 1.2rem;
        font-weight: 500;

        letter-spacing: 1.3px;

        color: currentColor;

        @include mq-pc {
            letter-spacing: 1.7px;
            font-size: 1.5rem;
        }
    }
}

.bread {
    font-size: 1rem;

    letter-spacing: 1px;

    a {
        display: inline-block;
        position: relative;

        margin-right: 24px;

        color: #8f8f8f;

        &:before {
            position: absolute;
            top: 4px;
            right: -14px;

            width: 4px;
            height: 4px;

            transform: rotate(45deg);

            border-top: 1px solid #8f8f8f;
            border-right: 1px solid #8f8f8f;

            @include content;
        }
    }

    p {
        display: inline-block;
        color: #8f8f8f;
    }
}

.num {
    position: absolute;
    top: 0;

    width: 20px;
    height: 100%;

    @include mq-pc {
        width: 24px;
    }

    span {
        font-size: 1.0rem;

        position: absolute;
        top: 0;
        bottom: 0;

        width: 90px;
        height: 20px;
        margin: auto;

        transform: translate3d(-33px, 0px, 0) rotate(-90deg);
        text-align: center;
        letter-spacing: 2px;

        @include mq-pc {
            letter-spacing: 3px;
            font-size: 1.2rem;
            width: 90px;
            height: 20px;
        }
    }
}

#cursor {
    @include mq-pc {


        position: fixed;
        z-index: 100;
        top: -40px;
        left: -40px;

        width: 80px;
        height: 80px;

        pointer-events: none;

        span {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            width: 10px;
            height: 10px;
            margin: auto;

            transition: all .4s;

            border: 1px solid $g;
            border-radius: 100%;
            background: $g;

            &:before {
                position: absolute;
                top: -14px;
                left: -14px;
                z-index: 1;
                width: 46px;
                height: 46px;

                transition: all 0.4s cubic-bezier(.49, -0.23, .53, 1.58);
                transform: translateX(0) translateY(0) translateZ(0) scale(0);
                border: 6px solid rgba(#fff, 0.2);
                border-radius: 100%;
                box-sizing: border-box;
                @include content;
            }

            &:after {
                position: absolute;
                top: -14px;
                left: -14px;
                z-index: 0;
                width: 46px;
                height: 46px;
                transform: translateX(0) translateY(0) translateZ(0) scale(0);
                transition: all 0.4s cubic-bezier(.49, -0.23, .53, 1.58);
                border: 14px solid rgba($g, 0.5);
                border-radius: 100%;
                box-sizing: border-box;
                @include content;
            }
        }

        &.is-menu {
            span {
                width: 60px;
                height: 60px;

                border: 1px solid $g;
                background: transparent;
            }
        }

        &.is-basic {
            span {
                width: 6px;
                height: 6px;

                border: 1px solid transparent;
                background: #fff;
            }
        }

        &.is-arw {
            span {
                width: 20px;
                height: 20px;

                border: 1px solid $g;
                background: transparent;

                &:before,
                &:after {
                    transform: translateX(0) translateY(0) translateZ(0) scale(1);
                }
            }
        }

        &.is-arw-w {
            span {
                width: 20px;
                height: 20px;

                border: 1px solid #fff;
                background: transparent;
            }
        }

        &.is-contact-w {
            span {
                width: 50px;
                height: 50px;

                border: 1px solid #fff;
                background: transparent;
            }
        }

        &.is-sns {
            span {
                width: 36px;
                height: 36px;

                border: 1px solid #fff;
                background: transparent;
            }
        }

        &.is-sns-g {
            span {
                width: 36px;
                height: 36px;

                border: 1px solid $g;
                background: transparent;
            }
        }

        &.is-contact-btn {
            span {
                width: 26px;
                height: 26px;

                border: 1px solid #fff;
                background: transparent;
            }
        }

        &.is-plus {
            span {
                width: 13px;
                height: 13px;

                border: 1px solid $g;
                background: transparent;
            }
        }
    }
}

// #bird {
//     position: fixed;
//     z-index: 100;
//     top: -20px;
//     left: -21px;

//     width: 42px;
//     height: 40px;

//     transition: opacity .4s;
//     pointer-events: none;

//     &.is-hide {
//         opacity: 0;
//     }

//     #bird-img {
//         position: absolute;
//         top: 0;
//         left: 0;

//         width: 42px;
//         height: 40px;

//         background-image: url('/resource/img/common/bird.png');
//         background-size: 100%;
//     }
// }

.sort-wrap {
    position: relative;
    z-index: 1;

    @include mq-sp {
        padding: 79px 0;
    }

    .wave {
        position: absolute;
        width: 100vw;
        top: -10vw;
    }

    .flex {

        @include mq-pc {
            padding-top: 120px;
        }

        strong {
            color: #909090;
        }

        .cell {
            .sort {

                &:before {
                    top: 7px;
                    right: 8px;

                    transform: rotate(45deg);
                }
            }
        }

        .c-1 {
            .sort {
                position: absolute;
            }
        }
    }

    .tab {
        position: relative;

        .current {
            margin: 0 0 0 20px;
            font-size: 1.2rem;
            letter-spacing: 3px;
            padding-bottom: 5px;
            width: 128px;
            border-bottom: 1px solid #909090;
            position: relative;
            color: #909090;
            z-index: 10;

            &:before {
                position: absolute;
                bottom: 14px;
                right: 8px;

                width: 4px;
                height: 4px;

                transform: rotate(135deg);
                transition: opacity .6s;
                border-top: 1px solid #909090;
                border-right: 1px solid #909090;

                @include content;
            }
        }

        .hide-box {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 0px;
            overflow: hidden;
            background: #fff;
            padding: 0 0 0 20vw;
            z-index: 50;

            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .sort {
                transition: all .6s;
                opacity: 0;
                width: 100%;
                color: #909090;
                letter-spacing: 2px;
                font-size: 1.2rem;
                padding-bottom: 11px;

                &.is-current {
                    color: $g;
                    pointer-events: none;
                }
            }
        }
    }
}

.cursor-pos {
    position: absolute;

    box-sizing: border-box;

    // border: 1px solid red;
    border-radius: 100%;

    &.basic {
        top: 6px;
        left: -18px;

        width: 6px;
        height: 6px;
        margin: auto;
    }
}

.btn-c {
    position: relative;

    width: 130px;
    padding-bottom: 7px;
    color: #595959;

    @include mq-pc {
        width: 167px;
    }

    &:hover {
        opacity: 1;

        @include mq-pc {
            p {
                color: $g;
            }

            span {
                &:before {
                    transform: translate3d(0%, 0, 0);
                }
            }
        }
    }

    p {
        font-size: 1.2rem;

        transition: color 1s;
        letter-spacing: 2px;
    }

    span {
        position: absolute;
        bottom: 0;
        left: 0;

        overflow: hidden;

        width: 100%;
        height: 1px;

        background-color: #ccc;

        &:before {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 1px;

            transition: transform 1s;
            transform: translate3d(-100%, 0, 0);

            background: $g;

            @include content;
        }
    }

    .arw {
        position: absolute;
        top: -1px;
        right: 3px;

        width: 18px;
        height: 18px;

        em {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            width: 4px;
            height: 4px;
            margin: auto;

            transform: rotate(45deg);

            border-top: 1px solid currentColor;
            border-right: 1px solid currentColor;
        }
    }

    .squre {
        position: absolute;
        top: 5px;
        right: 6px;

        width: 11px;
        height: 8px;

        @include mq-pc {
            top: 2px;
        }
    }

    .cursor-pos {
        top: -2px;
        right: 1px;
        width: 20px;
        height: 20px;
    }
}

#load {
    position: fixed;
    background: $g;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;

    .t {
        position: absolute;
        width: 100vw;

        left: 0;
        bottom: 99%;

        @include mq-pc {
            bottom: 99vh;
        }
    }

    .b {
        position: absolute;
        width: 100vw;
        top: 100%;
        left: 0;

        @include mq-pc {
            top: 100vh;
        }
    }
}

#prog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;

    &.is-hide {
        .logo {
            opacity: 0;
        }

        .t {
            opacity: 0;
        }

        #loader {
            opacity: 0;
        }
    }

    &.is-in {
        .logo {
            opacity: 1 !important;
        }
    }

    &.is-complete {
        .t {
            #prog-t {
                transform: translateX(0) translateY(-100%) translateZ(0);
            }

            #comment {
                transform: translateX(0) translateY(0) translateZ(0);
            }

            #prog-bird {
                transform: translateX(0) translateY(0) translateZ(0) scale(1) rotate(0deg);
            }
        }
    }

    .logo {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 110px;
        height: 50px;
        margin: auto;
        transition: opacity 0.4s;

        svg {
            fill: #fff;
        }

        @include mq-pc {
            left: auto;
            right: 8.4vw;
            width: 120px;
            height: 125px;
        }
    }

    .t {
        overflow: hidden;
        position: absolute;
        width: 400px;
        height: 29px;
        bottom: 65px;
        right: 30px;
        transition: opacity 0.4s;

        @include mq-pc {
            right: 8.5vw;
        }

        span {
            padding-top: 6px;
            letter-spacing: 1.6px;
            text-transform: uppercase;
            font-size: 1.2rem;
            position: absolute;
            color: #fff;
            top: 0;
            right: 0;
            text-align: right;
            transition: transform 1s;
            padding-right: 49px;
        }

        #prog-t {}

        #comment {
            transform: translateX(0) translateY(100%) translateZ(0);
        }

        #prog-bird {
            position: absolute;
            top: 4px;
            right: 10px;
            width: 20px;
            transform: translateX(0) translateY(0) translateZ(0) scale(0) rotate(-45deg);
            transform-origin: center;
            transition: transform 0.7s;
            transition-delay: .4s;
        }
    }

    #loader {
        position: absolute;
        right: 28px;
        bottom: 60px;
        width: 40px;
        height: 40px;
        transform: rotate(-90deg) translateZ(0);
        transition: opacity 0.4s;

        @include mq-pc {
            right: 8.4vw;
        }

        #c-loader {
            stroke-dashoffset: 244px;
            stroke-dasharray: 244px;
        }
    }
}

.first-hide {
    &.page-top {
        #mv-bg {
            .p1 {
                transform: translateX(0) translateY(24%) translateZ(0);
            }

            .p2 {
                transform: translateX(0) translateY(24%) translateZ(0);
            }

            .p3 {
                transform: translateX(0) translateY(16%) translateZ(0);
            }

            .p4 {
                transform: translateX(0) translateY(-24%) translateZ(0);
            }

            .p5 {
                transform: translateX(0) translateY(-38%) translateZ(0);
            }
        }
    }

    &.page-keep {
        #mv-bg {
            .p1 {
                transform: translateX(0) translateY(44%) translateZ(0);
            }

            .p2 {
                transform: translateX(0) translateY(-36%) translateZ(0);
            }

            .p3 {
                transform: translateX(0) translateY(-38%) translateZ(0);
            }
        }
    }
}

#menu {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 27px;

    width: 100%;
    height: 100%;
    padding-top: 0;
    pointer-events: none;
    transition: opacity 1s;
    opacity: 0;
    color: #fff;

    @include mq-pc {
        width: 250px;
        padding-top: 82px;
        left: 55px;

        .w {
            display: flex;
            align-items: center;
            height: calc(100vh - 242px);

            .in {
                width: 100%;
            }
        }
    }

    @include mq-sp {
        .wrapp {
            padding-top: 120px;
            overflow-y: scroll;
            height: 100%;
            padding-bottom: 50px;

        }
    }

    &.is-in {
        pointer-events: visible;
        opacity: 1;
    }

    a {
        position: relative;
        opacity: 1 !important;

        .cursor-pos {
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .logo {
        text-indent: -200px;
        position: absolute;
        top: 40px;
        left: 0;
        width: 80px;
        height: 40px;

        svg {
            position: absolute;
            top: 0;
            left: 0;
            fill: #fff;
        }

        @include mq-pc {
            top: 55px;
            left: 0;
            width: 115px;
            height: 55px;
        }
    }

    #close {
        position: absolute;
        top: 30px;
        left: 58vw;

        width: 94px;
        height: 19px;

        @include mq-pc {
            top: 48px;
            left: 300px;
        }

        .cursor-pos {
            top: -15px;
            left: -20px;
            width: 50px;
            height: 50px;
        }

        svg {
            position: absolute;
        }
    }

    .fit-bottom {

        @include mq-sp {
            margin-top: 8vh;
            // margin-left: 20px;
        }

        @include mq-pc {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 20px;
        }
    }

    .store_link {
        margin-bottom: 35px;

        @include mq-pc {
            margin-bottom: 5vh;
        }

        .cursor-pos {
            top: 2px;
            left: -6px;
            width: 20px;
            height: 20px;
        }

        .title {
            font-size: 1.4rem;
            letter-spacing: 2px;
            margin-bottom: 10px;
            position: relative;

            @include mq-pc {
                font-size: 1.5rem;
                margin-bottom: 1vh;
            }
        }

        .fx_link {
            margin-top: 10px;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            align-items: center;

            @include mq-pc {
                margin-top: 1vh;
            }

            > * + * {
                margin-left: 20px;
            }

            .t1 {
                font-size: 1.4rem;
                padding-top: 0;
                letter-spacing: 2px;
                position: relative;
                padding-left: 18px;

                @include mq-pc {
                    padding-left: 22px;
                    font-size: 1.5rem;
                }

                &:before {
                    @include content;
                    position: absolute;
                    top: 9px;
                    left: 0;

                    width: 4px;
                    height: 4px;
                    margin: auto;

                    transform: rotate(45deg);

                    border-top: 1px solid currentColor;
                    border-right: 1px solid currentColor;

                    @include mq-pc {
                        top: 9px;
                    }
                }
            }

            .ico {
                .cursor-pos {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
                svg {
                    fill: #fff;
                }
            }
        }
    }

    .list1 {
        margin-bottom: 30px;

        @include mq-pc {
            margin-bottom: 4vh;
        }

        .cursor-pos {
            top: 2px;
            left: -6px;
            width: 20px;
            height: 20px;
        }

        .t1 {
            font-size: 1.4rem;

            padding-top: 0;

            letter-spacing: 2px;
            margin-bottom: 25px;
            position: relative;
            padding-left: 18px;

            @include mq-pc {
                padding-left: 22px;
                font-size: 1.5rem;
                margin-bottom: 5vh;
            }

            &:before {
                @include content;
                position: absolute;
                top: 9px;
                left: 0;

                width: 4px;
                height: 4px;
                margin: auto;

                transform: rotate(45deg);

                border-top: 1px solid currentColor;
                border-right: 1px solid currentColor;

                @include mq-pc {
                    top: 9px;
                }
            }
        }

        .t2 {
            font-size: 1.4rem;
            letter-spacing: 2px;
            position: relative;
            padding-left: 18px;

            @include mq-pc {
                padding-left: 22px;
                font-size: 1.5rem;
            }

            &:before {
                @include content;
                position: absolute;
                top: 9px;
                left: 0;

                width: 4px;
                height: 4px;
                margin: auto;

                transform: rotate(45deg);

                border-top: 1px solid currentColor;
                border-right: 1px solid currentColor;

                @include mq-pc {
                    top: 10px;
                }
            }
        }
    }

    .flex {

        @include mq-sp {
            margin-bottom: 40px;
        }

        @include mq-pc {
            display: flex;
            flex-wrap: wrap;
        }

        .cursor-pos {
            left: 5px;
        }

        .sort {
            cursor: pointer;
            position: relative;
            font-size: 1.2rem;
            margin-bottom: 16px;
            letter-spacing: 1px;
            padding-left: 19px;

            @include mq-pc {
                padding-left: 23px;
                width: 50%;
                margin-bottom: 22px;
            }
        }
    }

    .list2 {
        display: flex;
        margin-bottom: 20px;

        @include mq-sp {
            width: 200px;

        }

        a {
            width: 50%;
            font-size: 1rem;
            margin-bottom: 10px;
            padding-left: 20px;
            letter-spacing: 1.1px;

            &:first-child {
                // padding-left: 0;

                // .cursor-pos {
                //     left: -24px;
                // }
            }

            &.arw {
                position: relative;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 4px;
                    height: 4px;
                    margin: auto;
                    transform: rotate(45deg);
                    border-top: 1px solid currentColor;
                    border-right: 1px solid currentColor;
                }
            }

            svg {
                position: absolute;
                top: 4px;
                left: 0px;
                width: 11px;
                height: 8px;

                .path {
                    fill: #fff !important;
                }
            }

            .cursor-pos {
                top: 0px;
                left: -4px;
                width: 20px;
                height: 20px;
            }

            @include mq-pc {
                font-size: 1rem;
            }
        }
    }

    .contact {
        margin: 0 0 24px 0;
        padding-bottom: 20px;

        border-bottom: 1px solid #fff;
        width: 200px;

        @include mq-pc {
            width: 100%;
            margin-bottom: 40px;
            padding-top: 20px;
        }

        p {
            font-size: 1.0rem;
            line-height: 2;

            letter-spacing: .8px;

            @include mq-sp {
                br {
                    display: none;
                }
            }
        }

        .btn-p {
            width: 176px;
            margin-top: 20px;
            margin-bottom: 0;

            @include mq-pc {
                width: 196px;
            }

            span {
                position: absolute;
                top: 18px;
                left: 0;

                width: 30px;
                height: 6px;

                @include mq-pc {
                    top: 14px;
                }
            }

            i {
                svg {
                    width: 11px;
                    height: 8px;
                    margin-right: 8px;
                }
            }

            svg {
                fill: #fff;
            }
        }
    }
}

.btn-p {
    position: relative;

    width: 260px;
    margin-bottom: 56px;
    padding: 13px 0;

    text-align: center;

    color: #fff;

    @include mq-pc {
        width: 280px;
    }

    &:hover {
        opacity: 1;
    }

    span {
        position: absolute;
        top: 13px;
        left: 18px;

        width: 30px;
        height: 14px;
    }

    .cursor-pos {
        top: -2px;
        left: -9px;

        width: 50px;
        height: 50px;
    }

    i {
        font-size: 1.2rem;
        font-style: normal;

        letter-spacing: 1.4px;

        @include mq-sp {
            text-indent: 12px;
        }
    }
}

.btn-more {
    font-size: 1rem;
    cursor: pointer;
    position: relative;
    text-align: center;
    letter-spacing: 1px;

    border-bottom: 1px solid #d8d8d8;

    transition: all .6s;
    padding-bottom: 4px;
    display: inline-block;
    color: #8A8A8A;

    @include mq-pc {
        font-size: 1.2rem;
        padding-bottom: 6px;
    }

    &:hover {
        @include mq-pc {
            opacity: 1;
            color: $g;
            border-bottom: 1px solid $g;

            &:before {
                background-color: $g;
            }

            &:after {
                background-color: $g;
            }
        }
    }

    .cursor-pos {
        width: 12px;
        height: 12px;
        top: 2px;
        left: 0px;
    }

    &:before {
        position: absolute;
        top: 5px;
        left: 6px;

        width: 1px;
        height: 5px;
        transition: all .6s;
        background-color: #8A8A8A;

        @include mq-pc {
            top: 6px;
        }

        @include content;
    }

    &:after {
        position: absolute;
        top: 7px;
        left: 4px;

        width: 5px;
        height: 1px;
        transition: all .6s;
        background-color: #8A8A8A;

        @include mq-pc {
            top: 8px;
        }

        @include content;
    }
}

.t-en {
    font-size: 1.4rem;
    line-height: 1.75;

    letter-spacing: 0px;

    @include mq-pc {
        font-size: 1.5rem;
        line-height: 1.7;
        letter-spacing: .1px;
    }
}

.t-jp {
    font-size: 1.3rem;
    line-height: 1.8;

    letter-spacing: 2.7px;

    @include mq-pc {
        font-size: 1.5rem;
        line-height: 1.8;

        letter-spacing: 2.7px;
    }
}

.info {
    position: relative;

    a {
        position: relative;

        display: flex;
        overflow: hidden;

        padding: 28px 0 28px;

        list-style: none;

        align-items: center;
        color: #8C8C8C;

        @include mq-sp {
            flex-wrap: wrap;
        }

        @include mq-pc {
            padding: 26px 0;
        }

        &:before {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 1px;

            background: #d8d8d8;

            @include content;
        }

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 1px;

            transition: transform .6s;
            transform: translate3d(-100%, 0, 0);

            background: $g;

            @include content;
        }

        &:hover {
            opacity: 1;

            @include mq-pc {
                color: $g;

                &:after {
                    transform: translate3d(0, 0, 0);
                }

                .arw {
                    opacity: 1;
                }
            }
        }

        .arw {
            @include mq-sp {
                display: none;
            }

            position: absolute;
            top: 24px;
            right: 2px;

            width: 18px;
            height: 22px;
            opacity:0;
            transition: opacity .6s;

            em {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                width: 4px;
                height: 4px;
                margin: auto;

                transform: rotate(45deg);

                border-top: 1px solid currentColor;
                border-right: 1px solid currentColor;
            }
        }

        .date {
            font-size: 1rem;

            width: 74px;

            letter-spacing: 1px;
            margin-bottom: 24px;

            @include mq-pc {
                width: 112px;
                margin-bottom: 0;
            }
        }

        .cat {
            font-size: 1rem;

            width: 142px;

            letter-spacing: 1px;
            margin-bottom: 24px;

            @include mq-pc {
                width: 182px;
                margin-bottom: 0;
            }
        }

        .t {
            @include mq-sp {
                width: 100%;
            }

            letter-spacing:1px;
            font-size: 1.5rem;
        }

        .cursor-pos {
            top: 25px;
            right: 0;

            width: 20px;
            height: 20px;
        }
    }
}

.pre-f {
    position: relative;
    background: #fff;
    padding-bottom: 60px;
    z-index: 10;

    @include mq-pc {
        padding-bottom: 6px;
    }

    .img {
        position: relative;
    }

    .wave1 {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        width: 100vw;
    }

    .wave2 {
        position: absolute;
        z-index: 10;
        top: 13.3vw;
        left: 0;

        width: 100vw;
    }

    .wave3 {
        position: absolute;
        z-index: 10;
        top: 15.9vw;
        left: 7;

        width: 100vw;
    }

    .wave4 {
        position: absolute;
        z-index: 1;
        top: -10.6vw;
        left: 0;

        width: 100vw;
    }

    #sand-wrap {
        overflow: hidden;
        position: relative;
        width: 100vw;
        height: 31vw;
        z-index: 0;
        top: 0;
        left: 0;

        #sand {
            position: absolute;
            width: 100vw;
            height: 31vw;
            top: 0;
            left: 0;
            z-index: 0;
        }
    }

    .sub {
        margin-top: 30px;
        margin-bottom: 16px;

        @include mq-sp {
            padding-right: 27px;
        }

        @include mq-pc {
            margin-top: 40px;
            margin-bottom: 68px;
        }
    }

    .contact {

        @include mq-sp {
            padding: 50px 20px 0 20vw;
        }

        @include mq-pc {
            display: flex;

            padding-right: 9vw;

            background-color: #fff;

            justify-content: flex-end;
        }

        .in {

            @include mq-pc {
                width: 500px;
                padding: 30px 0 50px;
            }

            h6 {
                font-size: 1.5rem;
                font-weight: 300;

                margin-bottom: 20px;
                color: #909090;

                @include mq-pc {
                    margin-bottom: 30px;
                    font-size: 2rem;
                }
            }

            p {
                font-size: 1.2rem;

                margin-bottom: 30px;
                color: #909090;
                letter-spacing: 2px;

                @include mq-pc {
                    margin-bottom: 50px;
                    font-size: 1.4rem;
                }
            }

            .btn-p {
                background-color: #000;
                transition: all .6s;

                width: 205px;
                text-align: center;
                font-size: 1.5rem;
                letter-spacing: 2px;

                @include mq-sp {
                    margin-bottom: 50px;
                }

                @include mq-pc {
                    text-indent: 46px;
                    width: 280px;
                    font-size: 1.5rem;
                }

                &:hover {
                    @include mq-pc {
                        background-color: $g;
                    }
                }

                .cursor-pos {
                    width: 26px;
                    height: 26px;
                    top: 9px;
                    left: 94px;
                }

                svg {
                    position: absolute;
                    top: 0;
                    left: 11px;
                    fill: #fff;

                    @include mq-pc {
                        left: 64px;
                    }
                }
            }

            .btn-more {
                width: 144px;
                margin-left: -15vw;

                @include mq-pc {
                    width: 174px;
                    margin-left: 322px;
                }
            }
        }
    }



    .info {
        position: relative;

        width: 94.6vw;
        margin-bottom: 60px;
        padding-left: 20.3vw;

        @include mq-pc {
            width: 91.7vw;
            margin-bottom: 60px;
            padding-left: 27.8vw;
        }

        .num {
            color: #8C8C8C;

            @include mq-sp {
                left: 16px;
            }

            @include mq-pc {
                right: -4.4vw;
            }
        }
    }

    .btn-c {
        margin-left: 5.7vw;

        @include mq-pc {
            margin-left: 8.4vw;
        }
    }
}

#f {
    position: relative;

    padding: 0 0 98px;

    color: #909090;
    background-color: #000;

    @include mq-pc {
        overflow: hidden;
        padding: 0 0 68px;
    }

    a {
        position: relative;

        &:hover {
            @include mq-pc {
                opacity: 1;
            }
        }
    }

    .wave {
        position: absolute;
        z-index: 1;
        top: -2px;
        left: 0;

        width: 100vw;
    }

    #plant {
        margin-bottom: 20px;

        @include mq-pc {
            // margin-bottom: 130px;
        }
    }

    .flex {

        position: relative;
        z-index: 1;
        padding: 0 20px 0 40vw;

        @include mq-pc {
            display: flex;
            padding: 0 0 0 27.8vw;
        }

        .cell {
            p {
                font-size: 1.2rem;

                margin-bottom: 14px;

                letter-spacing: 1px;

                @include mq-pc {
                    margin-bottom: 36px;
                }
            }

            .fx_link {
                display: flex;
                align-items: center;
                margin-bottom: 14px;
                font-size: 1.2rem;
                letter-spacing: 1px;

                @include mq-pc {
                    margin-bottom: 36px;
                }

                > * + * {
                    margin-left: 20px;
                }

                .pagelink {
                    max-width: calc(100% - 40px);
                }

                .ico {
                    width: 20px;
                    height: 20px;

                    svg {
                        fill: #909090;
                    }
                }


            }
        }

        .c-1 {

            @include mq-sp {
                margin-bottom: 50px;
            }

            @include mq-pc {
                width: 232px;
            }
        }

        .c-2 {

            @include mq-sp {
                margin-bottom: 50px;
            }

            @include mq-pc {
                width: 31.8vw;
                padding-right: 22%;
            }

            p {
                br {
                    display: none;
                }
            }


            .btn-p {
                width: 214px;
                color: #909090;

                @include mq-pc {
                    width: 200px;
                }

                .arw {
                    left: 0;

                    svg {
                        fill: #909090 !important;
                    }
                }
            }
        }

        .c-3 {
            @include mq-pc {
                margin-bottom: 80px;
            }

            a {

                @include mq-sp {

                    &:nth-child(1),
                    &:nth-child(3) {
                        margin-bottom: 40px;
                    }
                }

                @include mq-pc {
                    margin-bottom: 36px;
                }
            }

            .sns {
                margin-top: 65px;
                display: flex;

                a {
                    @include mq-pc {
                        margin-bottom: 0;
                    }
                }

                @include mq-pc {
                    margin-top: 74px;
                }

                p {
                    margin-right: 20px;

                    @include mq-pc {
                        margin-bottom: 0;
                    }
                }

                svg {
                    fill: #909090;
                }
            }
        }

        small {
            font-size: 1rem;

            display: block;

            @include mq-sp {
                margin-bottom: 20px;
                margin-top: 36px;
            }

            @include mq-pc {
                width: 231px;
            }
        }

        .policy {
            font-size: 1rem;

            letter-spacing: 1px;
        }
    }

    .to-top {
        font-size: 1.2rem;

        position: absolute;


        cursor: pointer;
        z-index: 2;

        transform-origin: bottom left;
        letter-spacing: 2.5px;
        color: #fff;
        animation-name: fader;
        animation-duration: 3s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-fill-mode: forwards;

        @include mq-sp {

            transform: translate3d(40px, 0, 0) rotate(-90deg);
            left: -6px;
            bottom: 101px;
        }

        @include mq-pc {
            transform: translate3d(154px, 0px, 0) rotate(-90deg);
            right: 3.3vw;
            bottom: 322px;
        }

        .cursor-pos {
            top: -1px;
            right: -25px;

            width: 20px;
            height: 20px;
        }

        .arw {
            position: absolute;
            top: 0;
            right: -16px;
            bottom: 0;

            width: 4px;
            height: 4px;
            margin: auto;

            transform: rotate(45deg);

            border-top: 1px solid currentColor;
            border-right: 1px solid currentColor;
        }

        @keyframes fader {
            0% {
                opacity: 0.4;
            }

            50% {
                opacity: 1;
            }

            100% {
                opacity: 0.4;
            }
        }
    }
}

.pagenation {
    margin: 80px 0 80px 0;
    position: relative;

    height: 60px;

    @include mq-pc {
        margin: 140px 0 120px 7.3vw;
    }

    &:before {
        @include content;
        width: 48px;
        height: 1px;
        background: #8C8C8C;
        position: absolute;
        top: 28px;
        left: 83px;

        @include mq-pc {
            width: 36px;
            left: 78px;
        }
    }

    a {
        color: #8C8C8C;
        display: inline-block;
        padding: 20px 40px;
        font-size: 1.2rem;
        letter-spacing: 1px;

        @include mq-pc {
            padding: 20px 30px;
        }

        &:hover {
            opacity: 1;

            @include mq-pc {
                color: $g;

                .arw {
                    border-top: 1px solid $g;
                    border-right: 1px solid $g;
                }
            }
        }

        .arw {

            position: absolute;

            width: 4px;
            height: 4px;
            margin: auto;

            transform: rotate(45deg);

            border-top: 1px solid currentColor;
            border-right: 1px solid currentColor;
        }
    }

    .prev,
    .previouspostslink {
        position: absolute;
        left: 0px;

        .arw {
            top: 26px;
            position: absolute;
            left: 20px;
            transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg);

            @include mq-pc {
                left: 10px;
            }
        }

        .cursor-pos {
            width: 20px;
            height: 20px;
            top: 18px;
            left: 10px;

            @include mq-pc {
                left: 1px;
            }
        }
    }

    .next,
    .nextpostslink {
        position: absolute;
        left: 100px;

        .arw {
            top: 26px;
            position: absolute;
            right: 20px;

            @include mq-pc {
                right: 10px;
            }
        }

        .cursor-pos {
            width: 20px;
            height: 20px;
            top: 18px;
            right: 10px;

            @include mq-pc {
                right: 1px;
            }
        }
    }
}

.page-top {

    .pre-f {
        .wave1 {
            top: 0vw;
        }

        .wave2 {
            top: 48.2vw;

            @include mq-pc {
                top: 53.2vw;
            }
        }

        .wave3 {
            top: 51.6vw;

            @include mq-pc {
                top: 56.1vw;
            }
        }

        #sand-wrap {
            height: 71.2vw;
        }
    }

    #v-img {
        position: fixed;

        overflow: hidden;

        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        @include mq-pc {
            .l {
                position: absolute;
            }

            .l0 {
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0.3;
            }

            .l1 {
                width: 285vh;
                top: 49.8vh;
                left: 0;
                z-index: 1;
            }

            .l2 {
                width: 236vh;
                top: 0vh;
                left: 0;
                z-index: 2;
            }

            .l3 {
                width: 170.0vh;
                top: 0vh;
                left: 112.9vh;
                z-index: 3;
            }

            .l4 {
                width: 283vh;
                top: 0vh;
                left: 0vh;
                z-index: 4;
            }

            .l5 {
                width: 233.6vh;
                top: 17.5vh;
                left: 49.2vh;
                z-index: 5;
            }

            .l6 {
                width: 83.8vh;
                top: 9.3vh;
                left: 158.9vh;
                z-index: 6;
            }

            .l7 {
                width: 122.9vh;
                top: 36.4vh;
                left: 138.0vh;
                z-index: 7;
            }

            .l8 {
                width: 118.2vh;
                top: 36.9vh;
                left: 136.3vh;
                z-index: 8;
            }

            .l9 {
                width: 86.1vh;
                top: 0vh;
                left: 38.5vh;
                z-index: 9;
            }

            .l10 {
                width: 76.0vh;
                top: 6vh;
                left: 119.5vh;
                z-index: 10;
            }
        }

        .img {
            @include mq-sp {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .pre-f {
        .sub {
            color: #8C8C8C;

            i {
                background-color: #D4D4D4 !important;
            }
        }
    }

    #mv {
        position: relative;
        background-color: #eeeee9;
        height: 153.7vw;

        @include mq-pc {
            height: 75.7vw;
        }

        .logo {
            position: absolute;
            top: 38.8vw;
            right: 28px;

            width: 65px;
            height: 70px;

            @include mq-pc {
                top: 25.2vw;
                right: 8.4vw;
                width: 120px;
                height: 125px;

                svg {
                    fill: #231815 !important;
                }
            }

            span {
                display: none;
            }
        }

        #mv-bg {
            position: absolute;

            top: 19vw;
            left: 0;
            width: 100vw;

            @include mq-pc {
                top: 16vw;
                left: 0;
                width: 80vw;
            }

            .p {
                position: absolute;
                height: 100vw;
                transition: transform 2s cubic-bezier(.34, 0, .22, 1);

                .l {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
            }

            .p1 {
                top: 24.4vw;
                left: 0vw;
                width: 90vw;
            }

            .p2 {
                top: 9.5vw;
                left: 27.0vw;
                width: 52.8vw;
                transition-delay: .1s;
            }

            .p3 {
                top: 0vw;
                left: 9.7vw;
                width: 59.6vw;
                transition-delay: 0.2s;
            }

            .p4 {
                top: 0.9vw;
                left: 23.7vw;
                width: 37.9vw;
            }

            .p5 {
                top: 17.1vw;
                left: 4.6vw;
                width: 55.8vw;
            }


        }

        .wave {
            position: absolute;
            z-index: 10;
            bottom: -2px;
            left: 0;

            width: 100vw;
            height: 23.06vw;
        }
    }

    .s {}

    #s1 {
        position: relative;
        z-index: 20;

        color: #E9E9E9;
        background: #000;

        .sub {
            position: relative;
            z-index: 10;
            top: -9px;
            right: 26px;

            color: #fff;

            @include mq-pc {
                top: -8.3vw;
                right: 0;
            }
        }

        .num {
            @include mq-sp {
                left: 15px;

                span {
                    top: 300px;
                    margin: 0;
                }
            }

            @include mq-pc {
                right: 2.7vw;
            }
        }

        .ttl {
            font-size: 3rem;
            font-weight: 600;
            line-height: 1.6;

            position: absolute;
            right: 20px;
            top: 52px;
            letter-spacing: 1px;

            @include mq-sp {
                width: 72vw;
                text-align: center;
            }

            @include mq-pc {
                top: 0;
                font-size: 6rem;
                right: 6vw;
                line-height: 1.5;
                letter-spacing: -.6px;
            }

            span {
                &:last-child {
                    @include mq-pc {
                        text-indent: 228px;
                    }

                }
            }
        }

        .t-box {
            padding: 204px 30px 40px 20vw;

            @include mq-pc {
                display: flex;

                padding: 194px 0 114px 12vw;
            }

            @include mq-sp {
                display: flex;
                flex-direction: column-reverse;
                align-items: flex-end;
            }

            .store_list {
                position: relative;
                width: 45%;
                padding-right: 7%;
                margin-left: 76px;
                margin-bottom: 40px;

                .group {
                    position: relative;

                    & + .group {
                        margin-top: 40px;
                    }
                }

                .title {
                    position: absolute;
                    top: 0;
                    left: -35px;
                    transform: rotate(90deg);
                    transform-origin: left top;
                    font-size: 15px;
                    letter-spacing: 2px;
                }

                @include mq-sp {
                    width: auto;
                    padding-right: 18%;
                    margin-top: 80px;
                    margin-left: 0;
                    margin-bottom: 0;

                    .title {
                        top: 100px;
                        left: auto;
                        right: -20%;
                        transform: rotate(90deg);
                        transform-origin: right top;
                        font-size: 11px;
                    }
                }
            }
            .store_fx {
                .item {
                    display: flex;
                    flex-wrap: wrap;

                    & + .item {
                        margin-top: 40px;
                    }

                    .imgwrap {
                        display: block;
                        width: 180px;

                        &:hover {
                            opacity: .8;
                        }
                    }
                    .conts {
                        width: calc( 100% - 180px );
                        padding-left: 50px;

                        .name {
                            margin-bottom: 30px;
                            font-size: 15px;
                            letter-spacing: 2px;
                        }

                        .fx_link {
                            display: flex;
                            align-items: center;

                            > * + * {
                                margin-left: 25px;
                            }

                            .btn-c {
                                color: #fff;

                                span {
                                    background-color: $g;

                                    &:before {
                                        background-color: #fff;
                                    }
                                }

                                &:hover {
                                    @include mq-pc {
                                        p {
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                            .ico {
                                svg {
                                    fill: #fff;
                                }
                            }
                        }
                    }
                }

                @include mq-sp {
                    .item {
                        & + .item {
                            margin-top: 40px;
                        }

                        .imgwrap {
                            width: 100%;
                        }
                        .conts {
                            width: 100%;
                            margin-top: 30px;
                            padding-left: 0;

                            .name {
                                margin-bottom: 15px;
                                font-size: 14px;
                            }
                            .btn-c {
                                color: #fff;

                                span {
                                    background-color: $g;

                                    &:before {
                                        background-color: #fff;
                                    }
                                }

                                &:hover {
                                    @include mq-pc {
                                        p {
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .t-en {
                margin-left: 76px;
                margin-bottom: 40px;

                i {
                    width: 12px;
                    margin-right: 6px;
                    height: 1px;
                    display: inline-block;
                    background: #fff;
                    vertical-align: middle;
                }

                @include mq-pc {
                    width: 45%;
                    padding-right: 7%;
                }
            }

            .t-jp {
                @include mq-sp {
                    margin-top: 30px;
                    padding-left: 50px;
                    // text-align: justify;
                }

                @include mq-pc {
                    width: 45%;
                    margin-top: 100px;
                    font-size: 1.4rem;
                }
            }
        }
    }

    #s2 {
        position: relative;

        width: 100%;
        height: 100vh;

        .wave {
            position: absolute;
            z-index: 10;
            top: -2px;
            left: 0;

            width: 100vw;
        }
    }

    #s3 {
        position: relative;
        z-index: 15;

        color: #909090;
        background-color: #e2e2dd;

        @include mq-sp {
            padding-bottom: 1px;
        }

        @include mq-pc {
            padding-bottom: 40px;
        }

        .wave1 {
            position: absolute;
            top: -11.1vw;
            left: 0;

            width: 100vw;
        }

        .wave2 {
            position: absolute;
            top: 0.9vw;
            left: 0;

            width: 100vw;
        }

        .sub {
            position: relative;
            z-index: 1;

            margin-bottom: 42px;
            padding-top: 20px;

            @include mq-sp {
                right: 26px;
            }

            @include mq-pc {
                width: 83.8vw;
                margin-bottom: 60px;
                padding-top: 186px;
            }
        }

        .area {
            width: calc(80.4vw - 20px);
            margin-left: 19.5vw;

            @include mq-pc {
                width: 75.5vw;
                margin-left: 16.2vw;
            }

            .img {
                position: relative;

                width: 100%;
                margin-bottom: 30px;

                background-color: #fff;

                &:hover {
                    opacity: 1;
                }

                @include mq-pc {
                    margin-bottom: 60px;
                }
            }

            .num {
                @include mq-sp {
                    left: calc(-20.8vw + 20px);
                }

                @include mq-pc {
                    right: -5.1vw;
                }

                &.sp {
                    @include mq-sp {
                        top: 0;
                        height: 188px;
                    }
                }
            }

            .bd {
                width: 100%;
                height: 1px;
                margin-bottom: 50px;

                background-color: #909090;

                @include mq-pc {
                    margin-bottom: 114px;
                }
            }

            .flex {
                position: relative;

                @include mq-pc {
                    display: flex;
                    justify-content: space-between;
                }

                .cell {

                    @include mq-pc {
                        width: 46.6%;
                    }

                    .ttl-link {
                        font-weight: 500;
                        line-height: 1.3;

                        letter-spacing: 3px;
                        margin-bottom: 20px;

                        @include mq-sp {
                            font-size: 2rem;
                        }

                        @include mq-pc {
                            font-size: 3rem;
                            margin-bottom: 20px;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }

                    small {
                        font-size: 1.5rem;
                        letter-spacing: 2px;
                        display: block;

                        @include mq-sp {
                            margin-bottom: 30px;
                        }
                    }

                    .t-jp {
                        text-align: justify;
                        line-height: 24px;
                    }
                }
            }

            .links {
                @include mq-sp {
                    margin-top: 50px;
                }

                @include mq-pc {
                    padding-right: 6%;
                }

                .ttl-link {
                    @include mq-pc {
                        margin-bottom: 35px !important;
                    }
                }

                .btn-c {
                    margin: 50px 0 80px -14vw;

                    @include mq-pc {
                        margin: 150px 0 0 -8vw !important;
                    }
                }
            }

            .btn-c {
                margin: 50px 0 80px -14vw;

                @include mq-pc {
                    margin: 100px 0 110px -8vw;
                }
            }
        }
    }
}

.common {
    .head {

        padding-left: 19.2vw;

        padding-top: 120px;
        margin-bottom: 36px;

        @include mq-sp {}

        @include mq-pc {
            display: flex;
            padding-top: 200px;
            padding-left: 16.2vw;
            margin-bottom: 0;
        }

        .cell {
            strong {
                margin-bottom: 28px;
                color: #595959;

                @include mq-sp {
                    font-size: 2.0rem;
                    letter-spacing: 3px;
                }
            }

            .sort {
                font-size: 1.2rem;

                position: relative;
                cursor: pointer;
                width: 100%;
                margin-bottom: 70px;
                padding-bottom: 6px;

                letter-spacing: 1.7px;
                color: #909090;

                border-bottom: 1px solid #d8d8d8;
                padding-right: 20px;
                transition: all .6s;

                &.is-current {
                    color: $g;

                    border-bottom: 1px solid $g;
                    pointer-events: none;
                }

                &:hover {
                    @include mq-pc {
                        color: $g;
                        border-bottom: 1px solid $g;
                    }
                }

                &:before {
                    position: absolute;
                    top: 3px;
                    right: 7px;

                    width: 4px;
                    height: 4px;

                    transform: rotate(135deg);

                    border-top: 1px solid currentColor;
                    border-right: 1px solid currentColor;

                    @include content;
                    transition: all .6s;
                }

                .cursor-pos {
                    width: 20px;
                    height: 20px;
                    right: 0px;
                    top: -3px;
                    bottom: auto;
                }
            }
        }

        .c-1 {

            position: relative;

            @include mq-pc {
                width: 26vw;
            }

            .cell {
                width: 100%;
            }

            .sort {
                width: 9.2vw;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        .c-3 {
            display: flex;

            width: 53.8vw;


            flex-wrap: wrap;

            .sort {
                width: 10.4vw;
                margin-right: 2.6vw;
            }
        }
    }

}

.page-green {

    .pre-f {
        .wave1 {
            svg {
                path {
                    fill: #eeeee9 !important;
                }
            }
        }

        .wave4 {
            display: none;
        }
    }

    #s1 {
        .flex {}

        .sort-wrap {
            @include mq-sp {
                padding-bottom: 20px;
            }
        }
    }

    #s2 {
        position: relative;

        overflow: hidden;

        padding: 80px 0 0;

        @include mq-pc {
            padding: 94px 0 0;
        }

        .sort-wrap {
            background-color: #eeeee9;
            padding: 50px 0;

            @include mq-pc {
                padding: 100px 0;
            }

            .flex {
                @include mq-sp {
                    padding-top: 0;
                }
            }
        }

        .wave1 {
            top: 185px;
            left: 0;
            position: absolute;
            width: 100vw;

            @include mq-pc {
                top: 228px;
            }
        }

        .wave2 {
            top: -9vw;
            left: 0;
            position: absolute;
            width: 100vw;

            @include mq-pc {
                top: -9vw;
            }
        }

        .list {
            position: relative;
            z-index: 1;

            overflow: hidden;

            width: 100vw;
            margin-bottom: 50px;
            padding-left: 20vw;

            @include mq-pc {
                padding-left: 0;
                // width: 118vw;
                margin-bottom: 320px;
                margin-left: 3.9vw;
            }

            .cell {

                position: relative;
                margin-bottom: 40px;
                transition: all 0s;
                width: 100%;

                @include mq-pc {
                    width: 27.4vw;
                    margin-bottom: 60px;
                    margin-left: 4.5vw;
                }

                &:before {
                    opacity: 0;
                    position: absolute;
                    bottom: 8px;
                    right: 8px;

                    width: 4px;
                    height: 4px;

                    transform: rotate(45deg);
                    transition: opacity .6s;
                    border-top: 1px solid currentColor;
                    border-right: 1px solid currentColor;

                    @include content;
                }

                &:hover {
                    @include mq-pc {
                        opacity: 1;

                        &:before {
                            opacity: 1;
                        }

                        .img {
                            &:after {
                                opacity: 0;
                            }

                            img {
                                filter: grayscale(1);
                            }

                            p {
                                opacity: 1;
                                transition-delay: 0.2s;
                            }
                        }
                    }
                }

                .img {
                    margin-bottom: 20px;
                    overflow: hidden;
                    position: relative;
                    background: #fff;

                    &:after {
                        @include content;
                        transition: opacity 0.6s;
                        background-color: rgba(#000, 0.2);
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    img {
                        transition: all .6s;
                    }

                    p {
                        transition: opacity 0.6s;
                        opacity: 0;
                        z-index: 2;
                        width: 100%;
                        height: 10px;
                        color: #fff;
                        position: absolute;
                        text-align: center;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                    }

                    .h-panel {
                        position: absolute;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        background: $g;
                        top: 0;
                        left: 0;
                        transform: translateX(0) translateY(135%) translateZ(0);

                        .w-t-wrap {
                            position: absolute;
                            top: -5.6vw;
                            left: 0;
                            width: 100%;

                        }

                        .w-b-wrap {
                            position: absolute;
                            bottom: -5.8vw;
                            left: 0;
                            width: 100%;

                        }
                    }
                }

                p {
                    font-size: 1.2rem;
                    color: #8C8C8C;
                    letter-spacing: 2px;
                }

                .cursor-pos {
                    width: 20px;
                    height: 20px;
                    right: 0;
                    bottom: 0;
                }
            }
        }

        .panel {
            position: absolute;
            z-index: 0;
            top: 240px;
            left: 0;

            width: 100%;
            height: 140%;

            background: #e4e4db;

            @include mq-pc {
                top: calc(228px + 14vw);
                height: 140%;
            }
        }
    }
}

.page-keep {

    .pre-f {
        .wave1 {
            svg {
                path {
                    fill: #EAEAE5 !important;
                }
            }
        }
    }

    #s1 {
        position: relative;
        width: 100%;
        height: 149.6vw;
        overflow: hidden;

        @include mq-sp {
            margin-bottom: 23px;
            background-image: url('/resource/img/keep/mv@sp.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        @include mq-pc {
            background: #f5f6f4;
            height: 61.2vw;
        }

        .flex {
            z-index: 10;
            position: relative;
        }

        #mv-bg {
            position: absolute;

            @include mq-pc {
                top: 0vw;
                left: 0;
                width: 100vw;
            }

            .p {
                position: absolute;
                height: 100vw;
                transition: transform 2s cubic-bezier(.34, 0, .22, 1);

                .l {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
            }

            .p1 {
                top: 21.6vw;
                left: 0vw;
                width: 66.5vw;
                z-index: 2;
            }

            .p2 {
                top: 16.8vw;
                left: 52.5vw;
                width: 7.5vw;
                transition-delay: .1s;
                z-index: 1;
            }

            .p3 {
                top: 0vw;
                left: 11.5vw;
                width: 50.7vw;
                transition-delay: 0.2s;
                z-index: 0;
            }


        }
    }

    #s2 {

        position: relative;
        z-index: 2;

        color: #8C8C8C;
        background: #E0E0D7;
        padding-bottom: 80px;

        @include mq-pc {
            padding-bottom: 340px;
        }

        .wave {
            position: absolute;
            width: 100%;
            left: 0;
            top: -22.9vw;
        }

        .ttl {
            padding-top: 40px;
            margin-left: 20%;
            font-size: 1.5rem;
            line-height: 2;
            letter-spacing: 3px;
            margin-bottom: 60px;

            @include mq-sp {
                text-align: justify;
                padding-right: 20px;

                br {
                    display: none;
                }
            }

            @include mq-pc {
                line-height: 2;
                letter-spacing: 3px;
                font-weight: 600;
                padding-top: 47px;
                margin-left: 40%;
                margin-bottom: 216px;
                font-size: 2rem;
            }
        }

        .nums {

            width: 80%;
            margin: 0 0 0 20%;
            position: relative;

            @include mq-pc {
                width: 78%;
                display: flex;
                justify-content: space-between;
                margin: 0 0 157px 16%;
            }

            .cell {
                position: relative;
                padding: 20px 0px;
                background: #e0e0d7;
                z-index: 1;
                font-weight: bold;

                @include mq-sp {
                    margin-bottom: 50px;

                    &:last-child {
                        margin-bottom: 34px;
                    }
                }

                @include mq-pc {
                    padding: 0 32px;
                }

                .num {

                    color: $g;

                    @include mq-sp {
                        position: relative;
                        display: inline-block;
                        font-size: 1.6rem;
                        margin-right: 8px;
                    }

                    @include mq-pc {
                        position: absolute;
                        font-size: 2rem;

                        width: 100%;
                        left: 0;
                        top: -60px;
                        text-align: center;
                    }
                }

                p {

                    font-size: 1.6rem;
                    letter-spacing: 1px;

                    @include mq-sp {
                        display: inline-block;
                    }

                    @include mq-pc {
                        text-align: center;
                        font-size: 2rem;
                    }
                }
            }

            .bar {
                position: absolute;
                top: 0;
                left: 48px;
                width: 1px;
                background: #000;
                height: 100%;

                z-index: 0;

                @include mq-pc {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    margin-top: 16px;
                }
            }
        }

        .flex {
            width: 95%;
            padding-left: 20.0%;
            margin-bottom: 60px;

            @include mq-pc {
                display: flex;
                justify-content: space-between;
                width: 94%;
                padding-left: 16.3%;
                margin-bottom: 100px;
            }

            .img {
                width: 100%;

                @include mq-sp {
                    margin-bottom: 30px;
                }

                @include mq-pc {
                    width: 45.6%;
                }
            }

            .t-box {
                width: 100%;
                position: relative;

                @include mq-pc {
                    width: 48.5%;
                    padding-right: 30px;
                }

                strong {
                    font-size: 1.6rem;
                    position: relative;
                    margin-bottom: 12px;
                    font-weight: bold;

                    @include mq-pc {
                        font-size: 2.0rem;
                        margin-bottom: 32px;
                    }

                    .num {
                        margin-right: 8px;
                        position: relative;
                        display: inline-block;

                        @include mq-pc {
                            margin-right: 25px;
                        }
                    }
                }

                p {
                    font-size: 1.5rem;
                    line-height: 1.9;

                    @include mq-sp {
                        text-align: justify;
                    }

                    @include mq-pc {
                        font-size: 1.5rem;
                        line-height: 1.9;
                    }
                }

                .btn-more {
                    @include mq-sp {
                        width: 168px;
                        margin-left: -15vw;
                        margin-top: 42px;
                        margin-bottom: -8px;
                    }

                    @include mq-pc {
                        position: absolute;
                        bottom: 0;
                        right: 30px;
                        width: 168px;
                    }
                }
            }
        }

        .img-l {
            width: 100%;

            @include mq-pc {
                width: 75.4%;
                margin-left: 16.3%;
            }
        }
    }

    #s3 {
        color: #8C8C8C;
        z-index: 3;
        position: relative;
        background-color: #EAEAE5;
        padding-bottom: 20px;

        @include mq-pc {
            padding-bottom: 156px;
        }

        .wave {
            position: absolute;
            width: 100%;
            left: 0;
            top: -14.3vw;
        }

        .ttl {
            padding: 20px 0 0 19.0vw;

            @include mq-pc {
                padding: 0 0 0px 16.2vw;
                position: relative;
                top: -42px;
            }

            p {
                font-size: 2.2rem;
                font-weight: 600;
                letter-spacing: 2px;
                margin-bottom: 23px;
                position: relative;
                color: #828282;

                @include mq-pc {
                    margin-bottom: 32px;
                    font-size: 4rem;
                }
            }

            .lead {
                font-size: 1.2rem;
                font-weight: 800;
                margin-bottom: 27px;
                position: relative;
                color: #828282;
                letter-spacing: 1.8px;

                @include mq-sp {
                    letter-spacing: 1px;
                    padding-left: 34px;
                }

                @include mq-pc {
                    padding-left: 50px;
                    margin-bottom: 10px;
                    font-size: 1.5rem;
                    letter-spacing: 1.1px;
                }

                &:before {
                    @include content;
                    position: absolute;
                    width: 21px;
                    height: 1px;
                    background: currentColor;
                    top: 7px;
                    left: 0;

                    @include mq-pc {
                        top: 9px;
                        left: 0px;
                        width: 30px;
                    }
                }
            }
        }

        .cell {
            // border: 1px solid red;
            width: 100vw;
            margin: 0 0 0 0;
            position: relative;
            padding: 23px 0 0 0;

            @include mq-pc {
                padding: 80px 0 0 0;
                width: 87.5%;
                margin: 0 0 0 10.5%;
            }

            &:nth-child(even) {

                .t-box {
                    @include mq-pc {
                        padding: 50px 8.5vw 50px 0;
                        margin-left: 6vw;
                    }

                    .btn-more,
                    .btn-arw {
                        @include mq-pc {
                            margin-left: -7vw;
                        }

                    }
                }

                .img {
                    @include mq-pc {
                        margin-left: 50%;
                    }
                }
            }

            .img {

                @include mq-sp {
                    width: 100%;
                    margin: 0 auto 30px;
                }

                @include mq-pc {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 50%;
                    display: flex;
                    align-items: center;
                }

                picture {
                    width: 100%;
                }
            }

            .t-box {
                padding: 0 20px 60px 19vw;
                color: #595959;

                @include mq-pc {
                    width: 50%;
                    min-height: 700px;
                    padding: 50px 8.5vw 50px 0;
                    margin-left: 46vw;
                }

                strong {
                    font-size: 1.4rem;
                    letter-spacing: 2.2px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    line-height: 25px;
                    color: #595959;

                    @include mq-pc {
                        margin-bottom: 20px;
                        letter-spacing: 2.4px;
                        line-height: 38px;
                        font-size: 2rem;
                    }
                }

                .t1 {
                    font-size: 1.5rem;
                    color: #828282;
                    letter-spacing: 1.2px;
                    font-weight: 600;
                    margin-bottom: 25px;

                    @include mq-pc {
                        margin-bottom: 30px;
                        font-size: 1.5rem;
                    }
                }

                .t2 {
                    font-size: 1.4rem;
                    line-height: 24px;
                    letter-spacing: 1.3px;

                    @include mq-sp {
                        text-align: justify;

                        br {
                            display: none;
                        }
                    }

                    @include mq-pc {
                        font-size: 1.5rem;
                    }

                }

                .btn-more {
                    width: 164px;
                    margin-top: 70px;
                    margin-left: -13vw;

                    @include mq-pc {
                        width: 184px;
                        margin-top: 90px;
                        margin-left: 23vw;
                    }
                }

                .btn-arw {
                    width: 178px;
                    margin-top: 70px;
                    margin-left: -13vw;
                    border-bottom: 1px solid #ccc;
                    padding-bottom: 4px;
                    position: relative;
                    font-size: 1.2rem;
                    letter-spacing: 2px;

                    &:hover {
                        opacity: 1;
                        color: $g;
                        border-bottom: 1px solid $g;
                    }

                    &:before {
                        @include content;
                        border-top: 1px solid currentColor;
                        border-right: 1px solid currentColor;
                        width: 4px;
                        height: 4px;
                        position: absolute;
                        top: 5px;
                        right: 11px;
                        transform: translateX(0) translateY(0) translateZ(0) rotate(45deg);
                    }

                    @include mq-pc {
                        margin-top: 90px;
                        margin-left: 23vw;
                        padding-bottom: 6px;
                    }

                    .cursor-pos {
                        top: -2px;
                        right: 2px;
                        width: 20px;
                        height: 20px;

                    }
                }
            }

            .bar {
                background-color: #D4D4D4;
                width: 86.7%;
                position: absolute;
                height: 1px;
                top: 0;
                right: 0;
                left: 0;
                margin: 0 auto;
            }
        }
    }
}

.page-green-detail {

    .pre-f {
        .wave1 {
            display: none;
        }
    }

    .fix-box {
        @include mq-sp {
            margin-left: 20vw;
            margin-bottom: 100px;
        }

        @include mq-pc {
            position: fixed;
            top: 0;
            width: 20%;
            left: 16.3vw;
            z-index: 1;
        }

        strong {
            margin-bottom: 25px;

            @include mq-sp {
                margin-bottom: 30px;
                font-size: 2.0rem;
            }
        }

        .bread {
            margin-bottom: 120px;

            @include mq-pc {
                margin-bottom: 40px;
            }

            a {}

            p {}
        }

        .t {
            font-size: 1.5rem;
            line-height: 1.75;
            margin: 0 0 26px 20vw;
            padding-right: 20px;
            letter-spacing: 1px;
            color: #8A8A8A;
            text-align: justify;

            @include mq-pc {
                padding-right: 0;
                margin: 0 0 50px 0;
            }
        }

        .tag {
            display: inline-block;
            padding: 1px 8px;
            background: #01473C;
            font-size: 1rem;
            letter-spacing: 1px;
            color: #fff;

            @include mq-sp {
                margin-left: 20vw;
            }

            @include mq-pc {
                padding: 1px 20px;
            }
        }
    }

    #s1 {
        @include mq-sp {
            padding-top: 120px;
        }

        .date {
            text-align: right;
            padding: 165px 60px 0 0;
            font-size: 1.2rem;
            color: #D4D4D4;
            margin-bottom: 17px;
        }

        .imgs {
            padding: 0 0 0 19.8vw;

            @include mq-pc {
                padding: 5px 0 0 48.3vw;
            }

            .img {
                margin-bottom: 30px;

                @include mq-pc {
                    margin-bottom: 50px;
                }
            }
        }

        .sort-wrap {
            background: transparent;
            padding-bottom: 80px;
            position: relative;

            @include mq-sp {
                padding-top: 80px;
            }

            @include mq-pc {
                padding-bottom: 200px;
            }

            .bar {
                background: #D4D4D4;
                position: absolute;
                top: 0;
                left: 19.6vw;
                width: calc(80.4vw - 20px);
                height: 1px;

                @include mq-pc {
                    left: 16.3vw;
                    width: 75.3vw;
                }
            }
        }
    }
}

.page-shop {

    #s1 {
        .sub {
            margin: 50px 0 50px 0;
            right: 30px;
            position: relative;
            color: #8C8C8C;

            i {
                background: #D4D4D4;
            }

            @include mq-pc {
                right: 0;
                margin: 50px 0 100px 0;
            }
        }
    }

    #s2 {
        @include mq-sp {
            margin: 0 auto;
        }

        width: calc(100vw - 40px);
        position: relative;
        color: #8C8C8C;
        z-index: 20;
        padding-bottom: 60px;

        @include mq-pc {
            margin-left: 16.3%;
            width: 75.6%;
            padding-bottom: 100px;
        }

        .cell {
            width: 100%;
            margin-bottom: 110px;

            @include mq-pc {
                margin-bottom: 196px;
            }

            .bar {
                width: 100%;
                height: 1px;
                background: #D4D4D4;
                margin: 0 22% 56px;

                @include mq-sp {
                    width: 78%;
                }

                @include mq-pc {
                    margin: 200px 0;
                }
            }

            .sub {
                width: calc(100% - 30px);
                margin-bottom: 60px;

                i {
                    background: #D4D4D4;
                }

                @include mq-pc {
                    width: 100%;
                    margin-bottom: 100px;
                }
            }

            .img {
                width: 100%;
                margin-bottom: 40px;

                @include mq-pc {
                    margin-bottom: 50px;
                }
            }

            .t-box {
                padding-left: 54.6vw;
                position: relative;

                @include mq-pc {
                    padding-left: 51.5vw;
                }

                .logo {
                    width: 100%;
                    margin-bottom: 20px;

                    > svg {
                        max-width: 220px;
                    }

                    @include mq-pc {
                        margin-bottom: 26px;
                    }
                }

                .ttl {
                    font-size: 1.5rem;
                    letter-spacing: 1px;
                    margin-bottom: 20px;

                    @include mq-pc {
                        margin-bottom: 34px;
                    }
                }

                .tx {
                    font-size: 1.2rem;
                    line-height: 20px;
                    text-align: justify;
                    // margin-top: 100px;

                    @include mq-pc {
                        line-height: 1.7;
                        font-size: 1.5rem;
                    }
                }

                .flex {


                    margin-bottom: 20px;

                    @include mq-pc {
                        display: flex;
                        margin-bottom: 24px;
                    }

                    .cell-l {
                        font-size: 1.0rem;
                        letter-spacing: 2px;
                        width: 100%;
                        position: relative;

                        @include mq-sp {
                            margin-bottom: 20px;
                        }

                        &:before {
                            @include content;
                            width: 54px;
                            height: 1px;
                            background-color: #ccc;
                            position: absolute;
                            top: 6px;
                            right: 0;

                            @include mq-pc {
                                width: 28px;
                                top: 7px;
                                right: -22px;
                            }
                        }

                        @include mq-pc {
                            font-size: 1.2rem;
                            width: 77px;
                        }
                    }

                    .cell-r {

                        font-size: 1.2rem;
                        line-height: 1.7;
                        letter-spacing: 2px;

                        @include mq-pc {
                            margin-top: -5px;
                            font-size: 1.5rem;
                            padding-left: 38px;
                            width: calc(100% - 77px);
                        }

                        p {}

                        .map {
                            font-size: 1.2rem;
                            margin-top: 20px;
                            display: inline-block;
                            position: relative;


                            &:hover {
                                @include mq-pc {
                                    opacity: 1;
                                }
                            }

                            @include mq-pc {
                                margin-top: 20px;
                            }

                            .cursor-pos {
                                width: 20px;
                                height: 20px;
                                top: -1px;
                                left: 54px;
                            }

                            .arw {

                                position: absolute;

                                width: 4px;
                                height: 4px;
                                margin: auto;
                                top: 7px;
                                right: -32px;

                                transform: rotate(45deg);

                                border-top: 1px solid currentColor;
                                border-right: 1px solid currentColor;
                            }
                        }
                    }
                }

                .sns {
                    margin-top: 20px;

                    @include mq-pc {
                        margin-top: 50px;
                    }

                    .ico {
                        display: inline-block;

                        svg {
                            fill: currentColor;
                            transition: all .6s;
                        }

                        &:hover {
                            @include mq-pc {
                                opacity: 1;

                                svg {
                                    fill: $g;
                                }
                            }
                        }
                    }

                    .ig {
                        margin-right: 36px;

                        @include mq-sp {
                            margin-right: 16px;
                            width: 16px;
                            height: 16px;
                        }
                    }

                    .fb {

                        @include mq-sp {
                            position: relative;
                            top: -1px;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }

                .btn-c {
                    @include mq-sp {
                        margin-top: 60px;
                        position: relative;
                        left: -54.4vw;
                    }

                    @include mq-pc {
                        position: absolute;
                        bottom: 0;
                        left: -8vw;
                    }
                }
            }
        }
    }
}

.page-company {
    .pre-f {
        .wave1 {
            display: none;
        }
    }

    #s2 {
        padding-top: 108px;
        padding-bottom: 120px;

        @include mq-pc {
            padding-top: 174px;
            padding-bottom: 220px;
        }

        .flex {
            width: 75.1%;
            margin: 0 0 0 19.8%;
            font-size: 1.2rem;
            letter-spacing: 1.0px;
            line-height: 1.7;
            color: #8F8F8F;
            display: flex;
            margin-bottom: 41px;

            @include mq-pc {
                font-size: 1.5rem;
                letter-spacing: 1px;
                width: 75.1%;
                margin: 0 0 0 16.4%;
                margin-bottom: 60px;
            }

            .cell {}

            .c-1 {
                width: 76px;

                // border: 1px solid;
                @include mq-pc {
                    width: 15.2%;
                }

                p {}

                small {
                    font-size: 1rem;

                    @include mq-pc {
                        font-size: 1.2rem;
                    }
                }
            }

            .c-2 {
                width: 31.8%;
                margin-top: 11px;
                background-color: #D7D7D7;
                height: 1px;

                @include mq-sp {
                    display: none;
                }

            }

            .c-3 {
                // border: 1px solid;
                width: calc(100% - 76px);

                @include mq-pc {
                    padding-left: 4.6vw;
                    width: 53%;
                }

                .t1 {
                    margin-top: 20px;

                    @include mq-pc {
                        margin-top: 50px;
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    a {
                        display: inline-block;
                    }
                }

                .t2 {
                    margin-top: 10px;

                    @include mq-pc {
                        margin-top: 22px;
                    }

                    a {
                        display: inline-block;
                    }
                }

                .j {
                    text-align: justify;
                }

                .map {
                    font-size: 1.2rem;
                    margin-top: 10px;
                    display: inline-block;
                    position: relative;


                    &:hover {
                        @include mq-pc {
                            opacity: 1;
                        }
                    }

                    @include mq-pc {
                        margin-top: 16px;
                    }

                    .cursor-pos {
                        width: 20px;
                        height: 20px;
                        top: -1px;
                        left: 50px;
                    }

                    .arw {

                        position: absolute;

                        width: 4px;
                        height: 4px;
                        margin: auto;
                        top: 7px;
                        right: -32px;

                        transform: rotate(45deg);

                        border-top: 1px solid currentColor;
                        border-right: 1px solid currentColor;
                    }
                }

                .flex {
                    margin: 0 0 10px 0;

                    @include mq-pc {
                        margin: 0 0 23px 0;
                    }

                    .cell {

                        &:first-child {
                            width: 75px;

                            @include mq-pc {
                                width: 115px;
                            }
                        }
                    }
                }

            }
        }
    }
}

.page-news {
    #s2 {
        padding: 20px 20px 150px 18.8vw;

        @include mq-pc {
            padding: 150px 8.3vw 280px 27.8vw;
        }

        .post-wrap {
            color: #8C8C8C;
            margin-bottom: 100px;

            @include mq-pc {
                margin-bottom: 240px;
            }

            .head {
                position: relative;

                display: flex;
                overflow: hidden;

                padding: 28px 0 28px;

                list-style: none;

                margin-bottom: 40px;

                @include mq-sp {
                    flex-wrap: wrap;

                }

                @include mq-pc {
                    padding: 24px 0;
                    margin-left: 11.2vw;
                }

                &:before {
                    position: absolute;
                    bottom: 0;
                    left: 0;

                    width: 100%;
                    height: 1px;

                    background: #d8d8d8;

                    @include content;
                }

                .date {
                    font-size: 1rem;

                    width: 74px;

                    letter-spacing: 1px;
                    margin-bottom: 24px;

                    @include mq-pc {
                        width: 112px;
                        margin-top: 4px;
                        margin-bottom: 0;
                    }
                }

                .cat {
                    font-size: 1rem;

                    width: 142px;

                    letter-spacing: 1px;
                    margin-bottom: 24px;

                    @include mq-pc {
                        width: 182px;
                        margin-top: 4px;
                        margin-bottom: 0;
                    }
                }

                .t {
                    @include mq-sp {
                        width: 100%;
                    }

                    font-size: 1.5rem;
                }
            }

            .post-detail {

                position: relative;
                padding-bottom: 20px;

                @include mq-pc {
                    padding-left: calc(11.2vw + 298px);
                    padding-bottom: 20px;
                }

                &:before {
                    position: absolute;
                    bottom: 0;
                    left: 0;

                    width: 100%;
                    height: 1px;

                    background: #d8d8d8;

                    @include content;
                }

                img,
                p {
                    margin: 10px 0 20px;

                    @include mq-pc {
                        margin: 10px 0 40px;
                    }
                }

                p {
                    font-size: 1.5rem;
                    letter-spacing: 1px;
                    line-height: 1.7;
                }
            }
        }

        .btn-back {
            position: relative;

            width: 130px;
            padding-bottom: 7px;
            color: #8C8C8C;
            margin-left: calc(-19vw + 20px);

            @include mq-pc {
                width: 115px;
                margin-left: -7vw;
            }

            &:hover {
                opacity: 1;

                @include mq-pc {
                    p {
                        color: $g;
                    }

                    span {
                        &:before {
                            transform: translate3d(0%, 0, 0);
                        }
                    }
                }
            }

            p {
                font-size: 1.2rem;

                transition: color 1s;
                letter-spacing: 2px;
                text-align: right;
            }

            span {
                position: absolute;
                bottom: 0;
                left: 0;

                overflow: hidden;

                width: 100%;
                height: 1px;

                background-color: #d8d8d8;
            }

            .arw {
                position: absolute;
                top: -1px;
                left: 0;

                width: 18px;
                height: 18px;

                em {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    width: 4px;
                    height: 4px;
                    margin: auto;

                    transform: rotate(-135deg);

                    border-top: 1px solid currentColor;
                    border-right: 1px solid currentColor;
                }
            }

            .cursor-pos {
                top: -1px;
                left: -1px;
                width: 20px;
                height: 20px;
            }
        }

        .pagenation {

            @include mq-pc {
                margin: 140px 0 120px -20vw;
            }

            .wp-pagenavi {
                .prevpostslink {
                    position: absolute;
                    top: 0;
                    left: 0px;
                }

                .nextpostslink {
                    position: absolute;
                    top: 0;
                    left: 100px;
                }
            }
        }
    }
}

.page-policy {
    #s2 {
        padding: 20px 20px 150px 18.8vw;

        @include mq-pc {
            padding: 174px 8.3vw 280px 48.3vw;
        }

        .policy {
            color: #8C8C8C;

            p {
                font-size: 1.5rem;
                line-height: 1.7;
                letter-spacing: 1px;
            }

            strong {
                margin: 27px 0;
                font-size: 1.5rem;
            }
        }
    }

    .pre-f {
        display: none;
    }
}

.page-contact {
    .pre-f {
        display: none;
    }

    #s2 {
        padding: 0 20px 0 19vw;

        @include mq-pc {
            padding: 0 8.2vw 0 24vw;
        }

        .head {
            margin-top: 84px;
            font-size: 1.2rem;
            line-height: 1.7;
            letter-spacing: 1px;
            padding: 0 0 39px 0px;
            margin-bottom: 10px;
            border-bottom: 1px solid #d8d8d8;
            color: #8F8F8F;

            @include mq-pc {
                font-size: 1.5rem;
                margin-top: 176px;
                padding: 0 0 100px 36%;
                margin-bottom: 102px;
            }

            a {
                width: 260px;
                padding: 13px 0;
                background-color: #000;
                color: #fff;
                margin-top: 80px;
                text-align: center;
                font-size: 1.3rem;
                letter-spacing: 1px;

                &:hover {
                    @include mq-pc {
                        background: $g;
                        opacity: 1;
                    }
                }
            }
        }

        .b-none {
            border-bottom: none;
        }
    }

    #form {
        padding-top: 32px;
        padding-bottom: 60px;

        @include mq-pc {
            padding-top: 40px;
            padding-bottom: 100px;
        }

        .table {
            position: relative;
            color: #8F8F8F;

            @include mq-pc {}

            .cell {
                position: relative;
                margin-bottom: 22px;

                @include mq-pc {
                    display: flex;
                    margin-bottom: 40px;
                }

                &.is-error {
                    .error-txt {
                        opacity: 1 !important;
                    }
                }

                .cell-m {
                    width: 100%;
                    font-size: 1.2rem;

                    @include mq-sp {
                        margin-bottom: 10px;
                    }

                    @include mq-pc {
                        padding-top: 17px;
                        font-size: 1.5rem;
                        width: 36%;
                        height: 60px;
                    }
                }

                .cell-f {
                    font-size: 1.5rem;
                    position: relative;
                    width: 100%;
                    height: 40px;
                    background: #E8E8E8;

                    @include mq-pc {
                        width: 64%;
                        height: 52px;
                        font-size: 1.5rem;
                    }

                    span {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                    }

                    input {
                        border-radius: 0;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        border: none;
                        background: transparent;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        font-size: 1.2rem;
                        color: #595959;
                        text-indent: 18px;

                        @include mq-pc {
                            text-indent: 44px;
                            font-size: 1.5rem;
                        }
                    }

                    .error-txt {
                        position: absolute;
                        bottom: 51px;
                        right: 0;
                        color: red;
                        font-size: 1.2rem;
                        opacity: 0;
                        transition: opacity .6s;

                        @include mq-pc {
                            bottom: 58px;
                        }
                    }

                    .select {
                        position: relative;
                        overflow: hidden;

                        @include mq-pc {}

                        &:before {
                            @include content;
                            position: absolute;
                            top: 12px;
                            right: 15px;

                            width: 4px;
                            height: 4px;
                            margin: auto;

                            transform: rotate(-45deg);

                            border-top: 1px solid #595959;
                            border-right: 1px solid #595959;

                            @include mq-pc {
                                top: 17px;
                                right: 23px;
                            }
                        }

                        &:after {
                            @include content;
                            position: absolute;
                            top: 24px;
                            right: 15px;

                            width: 4px;
                            height: 4px;
                            margin: auto;

                            transform: rotate(135deg);

                            border-top: 1px solid #595959;
                            border-right: 1px solid #595959;

                            @include mq-pc {
                                top: 32px;
                                right: 23px;
                            }
                        }

                        select {
                            -moz-appearance: none;
                            -webkit-appearance: none;
                            position: absolute;
                            font-size: 1.2rem;
                            outline: none;
                            border: 0;
                            top: 0;
                            left: 0;
                            width: 110%;
                            background: transparent;
                            outline: none;
                            padding: 8px 0 0 26px;
                            color: #595959;

                            @include mq-pc {
                                font-size: 1.5rem;
                                padding: 16px 0 0 50px;
                            }

                            option {
                                -moz-appearance: none;
                                -webkit-appearance: none;
                            }
                        }
                    }

                    .textarea {
                        textarea {
                            color: #595959;
                            border: none;
                            resize: none;
                            background: #E8E8E8;
                            height: 280px;
                            padding: 14px 26px;
                            font-size: 1.2rem;
                            border-radius: 0px;

                            @include mq-pc {
                                font-size: 1.5rem;
                                height: 500px;
                                padding: 20px;
                            }
                        }
                    }
                }

                .h {
                    height: 280px;

                    @include mq-pc {
                        height: 500px;
                    }
                }
            }

            .submit-wrap {
                margin-top: 60px;
                padding-left: 0;

                @include mq-pc {
                    padding-left: 36%;
                }

                .btn-wrap {
                    @include mq-pc {}

                    #confirm {
                        background-color: #000;
                        width: 210px;
                        color: #fff;
                        text-align: center;
                        padding: 13px;
                        font-size: 1.2rem;
                        position: relative;

                        transition: all .6s;

                        &:hover {
                            background-color: $g;
                        }

                        @include mq-pc {
                            padding: 13px;
                            font-size: 1.5rem;
                            width: 280px;
                        }

                        input {
                            opacity: 0;
                            padding: 20px;
                            color: #fff;
                            top: 0;
                            left: 0;
                            position: absolute;

                            @include mq-pc {}
                        }
                    }
                }
            }
        }
    }
}

#modal-cont {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity .6s;

    &.is-in {
        opacity: 1;
        pointer-events: visible;
    }

    #modal-btn-close {
        position: absolute;
        top: 9px;
        right: 8px;
        width: 60px;
        height: 54px;
        z-index: 12;

        @include mq-pc {
            top: 6px;
            right: 40px;
            width: 106px;
            height: 106px;
        }

        .cursor-pos {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            width: 60px;
            height: 60px;
            margin: auto;
        }

        span {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;

            @include mq-pc {
                width: 30px;
                height: 30px;
            }

            &:before {
                @include content;
                position: absolute;
                height: 1px;
                width: 100%;
                background-color: #000;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                transform: translateX(0) translateY(0) translateZ(0) rotate(-45deg);
            }

            &:after {
                @include content;
                position: absolute;
                height: 1px;
                width: 100%;
                background-color: #000;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                transform: translateX(0) translateY(0) translateZ(0) rotate(45deg);
            }
        }
    }

    .modal-wrap {
        color: #8C8C8C;
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        width: calc(100% - 20px);
        height: 100%;
        margin-left: 20px;
        padding-left: 14.3%;
        padding-right: 20px;
        opacity: 0;
        overflow-y: scroll;
        z-index: 10;
        transition: opacity .6s;
        pointer-events: none;

        @include mq-pc {
            width: 83.8%;
            margin-left: 16.2%;
            padding-left: 11.5%;
            padding-right: 8.3%;
        }

        &.is-in {
            opacity: 1;
            pointer-events: visible;
        }

        .qa {
            color: $g;
            padding-bottom: 60px;

            @include mq-pc {
                padding-bottom: 100px;
            }

            .f {
                margin-bottom: 46px;
                font-size: 1.5rem;
                line-height: 1.7;
                letter-spacing: 1px;

                @include mq-pc {}

                .c1 {
                    position: relative;
                    width: 20vw;

                    @include mq-pc {
                        width: 17.2vw;
                    }

                    .num {
                        position: relative;
                        background: $g;
                        top: 4px;
                        right: 0;
                        width: 20px;
                        border-radius: 4px;
                        padding: 4px 5px;
                        text-align: center;
                        font-size: 1.3rem;
                        line-height: 1;
                        height: 20px;
                        color: #fff;

                        @include mq-pc {
                            padding: 4px 6px;
                            position: absolute;
                            right: 0;
                        }
                    }
                }

                .c2 {
                    position: relative;
                    width: 54.8vw;
                    padding-left: 0;

                    @include mq-pc {
                        width: 46.2vw;
                        padding-left: 3.3vw;
                    }

                    &:before {
                        @include content;
                        position: absolute;
                        top: 12px;
                        left: -7.9vw;
                        width: 4.3vw;
                        height: 1px;
                        background: $g;

                        @include mq-pc {
                            top: 13px;
                            left: -12.6vw;
                            width: 12.5vw;
                        }
                    }
                }

                .q {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 26px;

                    @include mq-pc {
                        margin-bottom: 20px;
                    }
                }

                .a {
                    display: flex;
                    flex-wrap: wrap;

                    .c2 {
                        @include mq-pc {
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .rental {
            color: $g;
            padding-bottom: 60px;

            @include mq-pc {
                padding-bottom: 100px;
            }

            .img {
                margin-bottom: 60px;

                @include mq-pc {
                    margin-bottom: 100px;
                }
            }

            .t1 {
                letter-spacing: 1.5px;
                font-size: 1.5rem;
                padding-bottom: 52px;
                margin-bottom: 53px;
                border-bottom: 1px solid currentColor;

                @include mq-pc {}
            }

            .f {

                @include mq-pc {
                    display: flex;
                    justify-content: space-between;
                }

                .c {
                    @include mq-sp {
                        margin-bottom: 33px;
                    }

                    @include mq-pc {
                        width: 25%;
                    }

                    .tt {
                        position: relative;
                        padding-left: 31px;
                        margin-bottom: 20px;
                        font-size: 1.5rem;

                        @include mq-pc {
                            margin-bottom: 20px;
                            padding-left: 31px;
                        }

                        .num {
                            position: absolute;
                            background: $g;
                            top: 2px;
                            left: 0;
                            width: 20px;
                            border-radius: 4px;
                            padding: 4px 6px;
                            text-align: center;
                            font-size: 1.3rem;
                            line-height: 1;
                            height: 20px;
                            color: #fff;

                            @include mq-pc {
                                top: 0;
                            }
                        }
                    }

                    .f2 {
                        display: flex;
                        font-size: 1.2rem;
                        letter-spacing: 0px;

                        @include mq-pc {}

                        .l {
                            width: 67px;

                            @include mq-pc {}
                        }

                        .r {
                            width: calc(100% - 67px);

                            @include mq-pc {}
                        }
                    }
                }
            }

            small {
                display: block;
                margin-top: 40px;
                font-size: 1.2rem;
                letter-spacing: 1px;

                @include mq-pc {}
            }
        }

        .support {
            color: $g;
            padding-bottom: 60px;

            @include mq-pc {
                padding-bottom: 100px;
            }

            .img {
                margin-bottom: 56px;

                @include mq-pc {
                    margin-bottom: 140px;
                }
            }

            .f {
                font-size: 1.5rem;
                letter-spacing: 1.5px;
                line-height: 1.7;

                @include mq-pc {
                    display: flex;
                }

                .c1 {
                    @include mq-sp {
                        margin-bottom: 48px;
                    }

                    @include mq-pc {
                        width: 13vw;
                        margin-right: 11vw;
                    }

                    p {}
                }

                .c2 {
                    @include mq-pc {
                        width: 40vw;
                    }

                    .tt {
                        margin-bottom: 27px;
                        font-weight: 600;

                        @include mq-pc {
                            margin-bottom: 20px;
                        }
                    }

                    .f2 {
                        margin-bottom: 22px;

                        @include mq-pc {
                            display: flex;
                            margin-bottom: 40px;
                        }

                        .c {
                            @include mq-pc {
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }

        .modal-ttl {
            padding: 48px 0 53px 0;
            font-size: 2rem;
            letter-spacing: 3px;
            color: $g;

            @include mq-pc {
                padding: 96px 0 190px 0;
                font-size: 3rem;
                letter-spacing: 3px;
            }
        }

        .flex {
            position: relative;
            display: flex;
            padding-left: 5vw;

            &:before {
                @include content;
                width: 1px;
                height: 100%;
                background: #D4D4D4;
                position: absolute;
                left: 0;
                top: 6px;
            }

            &:after {
                @include content;
                width: 5px;
                height: 5px;
                background: $g;
                position: absolute;
                left: -2px;
                top: 4px;
                border-radius: 100%;
            }
        }

        .t-box {
            position: relative;
            padding-bottom: 130px;

            .t-v {
                position: absolute;
                font-size: 1rem;
                letter-spacing: 1px;
                top: 0;
                left: -9vw;
                transform: translateX(10px) translateY(6px) translateZ(0) rotate(90deg);
                transform-origin: top left;
            }

            .img {
                position: absolute;
                top: 54px;
                left: 3vw;
                width: 3.8vw;
                height: 53px;
                background-color: red;
            }

            .cell {
                padding-left: 11vw;
                width: 27.5vw;

                .t1 {
                    font-size: 2rem;
                    letter-spacing: 2px;
                    margin-bottom: 20px;
                }

                .t2 {
                    font-size: 1.5rem;
                    letter-spacing: 1px;
                    line-height: 1.7;
                    margin-bottom: 20px;
                }

                .box {
                    font-size: 1.2rem;
                    letter-spacing: 1px;
                    line-height: 1.7;
                    display: flex;
                    margin-bottom: 11px;

                    .l {
                        width: 68px;
                    }

                    .r {
                        width: calc(100% - 68px);

                        small {
                            margin-top: 8px;
                            display: block;
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }

    .panel {
        position: absolute;
        z-index: 1;
        background: rgba(255, 255, 255, .5);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}